/**
 * 时间规则：
 *    小于1分钟：刚刚
 *    大于等于1分钟，小于60分钟：XX分钟前
 *    大于等于60分钟，小于24小时：XX小时前
 *    大于等于24小时：具体日期
 */
export default class TimeUtil {
  static convertTime(time: number): string {
    const dateTime = new Date(time);
    return TimeUtil.getTimeDescription(dateTime);
  }

  static convertTimeMd(time: number): string {
    const dateTime = new Date(time);
    const monthName = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    return `${monthName[dateTime.getMonth()]} ${dateTime.getDate()}`;
  }

  private static getTimeDescription(dateTime: Date): string {
    const now = new Date();
    const difference = now.getTime() - dateTime.getTime();
    const timeUnits: { [key: number]: () => string } = {
      [1000 * 60 * 60]: () => TimeUtil.convertTimeMd(dateTime)
    };
    for (const unit in timeUnits) {
      if (difference >= parseInt(unit)) {
        return timeUnits[unit]();
      }
    }
    return 'moments ago';
  }
}
